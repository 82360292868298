<template lang="">
    <div>
        
    </div>
</template>
<script>
export default {
    methods:{
        getView (){ this.$http.get('/ViewAccess/plan')
        .then(() => { 
        })
        .catch(() => { 
        })},
    },
    created(){
        this.getView()
    }
}
</script>
<style lang="">
    
</style>